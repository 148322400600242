.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 5ch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header_grid {
  display: grid;
  grid-template-columns: 33% 33% auto;
  border-bottom: solid 1px #ababab;
}

.acenter {
  text-align: center;
}

.btn_cursor {
  cursor: pointer;
}

.link_tit {
  color: black;
  text-decoration: none;
}

.form {
  display: flex;
  padding: 5px;
  flex-direction: column;
}

.login {
  border: none;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  padding: 10px;
  font-weight: 700;
  transition: 0.2s;
  width: 75%;
}

.login:active,
.login:focus,
.login:hover {
  outline: none;
  border-bottom-color: rgb(68, 112, 67);
}

.btn {
  border: none;
  width: 75%;
  background-color: rgb(68, 112, 67);
  color: white;
  padding: 15px 0;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
}

.mbtn {
  border: none;
  width: 75%;
  background-color: rgb(28, 61, 204);
  color: white;
  padding: 15px 0;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem auto;
  border: 1px solid #ddd;
  background-color: white;
}

/* 테이블 행 */
th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

th {
  background-color: #1b1b1b;
  color: #ddd;
}

/* 테이블 올렸을 때 */
tbody tr:hover {
  background-color: #d3d3d3;
  opacity: 0.9;
  cursor: pointer;
}

/* 테이블 비율 */
th:nth-child(1),
td:nth-child(1) {
  width: 10%;
}

th:nth-child(2),
td:nth-child(2) {
  width: 10%;
}

th:nth-child(3),
td:nth-child(3) {
  width: 30%;
}

.col-md-1 {
  width: 10%;
}
.col-md-2 {
  width: 20%;
}
.col-md-3 {
  width: 30%;
}
.col-md-4 {
  width: 40%;
}

.col-md-5 {
  width: 50%;
}

.custom-select {
  min-width: 100px;
}

select {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 100%;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}

.custom-input {
  min-width: 160px;
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 120px;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}
